<template>
  <div>
    <div
      :class="`bg_col_fff bor_rad_8 ${
        isLandscape
          ? 'pos_fixed l_0 t_0 w_100_vh h_100_vw z_4'
          : 'h_88_vw box_sha_0_0_8_8_black_80_per m_0_16 m_t_16'
      }`"
    >
      <div class="h_100_per dis_flex flex_dir_col p_16">
        <div class="dis_flex ali_it_cen">
          <div class="flex_1 font_16 font_bold">平台经纪人注册走势</div>

          <!-- 选项卡 -->
          <div v-if="isLandscape" class="dis_flex m_r_32">
            <!-- 项 -->
            <div
              v-for="(item, index) in tabArr"
              :key="index"
              class="font_14"
              @click="
                tabIndex = index;
                getTrendChart();
              "
            >
              <span v-show="index > 0" class="m_0_8">/</span>
              <span :class="`${tabIndex == index ? 'col_2396F5' : ''}`">{{
                item.label
              }}</span>
            </div>

            <!--  -->
          </div>

          <!-- 全屏|关闭 -->
          <div class="font_14 col_2396F5" @click="landscapeChange()">
            {{ isLandscape ? '关闭' : '全屏' }}
          </div>

          <!--  -->
        </div>

        <!-- 图表 -->
        <Chart
          v-if="!loading"
          ref="chart"
          class="flex_1 z_0 m_t_8"
          :options="options"
        />
        <div v-else class="flex_1 dis_flex ju_con_cen ali_it_cen">
          <van-loading type="spinner" />
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>

<script>
import { columnAmount } from '@/utils/tools';

import { brokerDashboardAppRegisterTrendChartPost } from '@/api/index';

export default {
  components: {
    Chart: () => import('@/components/Chart.vue'),
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
      options: {},
      isLandscape: false,
      filterForm: {},

      governorList: [],
      brokerProductList: [],

      scrollTop: 0,

      //
    };
  },
  computed: {
    // 选项卡
    tabArr: () => [
      {
        label: '按周',
        value: 'CURRENT_WEEK',
      },
      {
        label: '按月',
        value: 'CURRENT_MONTH',
      },
    ],

    //
  },
  mounted() {
    this.getTrendChart();

    //
  },
  destroyed() {
    document.body.style = null;
  },
  methods: {
    columnAmount,

    // 横屏 变化
    landscapeChange() {
      this.isLandscape = !this.isLandscape;

      // 横屏
      if (this.isLandscape) {
        this.scrollTop =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop;
        document.body.style =
          'width: 100vh;height: 100vw;transform: translate(100vw,0) rotate(90deg);transform-origin: 0 0;';
      } else {
        document.body.style = null;
        window.scrollTo({
          top: this.scrollTop,
        });
      }

      // 刷新图表
      this.loading = true;
      this.$nextTick(() => {
        this.loading = false;
      });

      //
    },

    // 获取 走势图
    async getTrendChart() {
      this.loading = true;

      const data = await brokerDashboardAppRegisterTrendChartPost({
        duration: this.tabArr[this.tabIndex].value,
        ...this.filterForm,
      });

      this.loading = false;

      // 柱状图
      this.options = {
        grid: {
          left: '1%',
          top: '8%',
          right: '0%',
          bottom: '0%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (e) => {
            const o = e[0];
            return [
              `<b>${o.name}</b>`,
              `数量：${columnAmount(o.value, { fixed: 0 })}`,
            ].join('<br />');
          },
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            fontSize: this.tabArr[this.tabIndex].label == '按周' ? 14 : 10,
            rotate: 70,
          },
          data: data.map((it) => it.gmtCreate),
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
        },
        series: [
          {
            type: 'bar',
            barWidth: '70%',
            itemStyle: {
              barBorderRadius: [4, 4, 0, 0],
              color: new window.echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: '#91CAFA',
                },
                {
                  offset: 1,
                  color: '#2396F5',
                },
              ]),
            },
            data: data.map((it) => it.reigsterCount),
          },
        ],
      };

      //
    },

    //
  },
};
</script>
